h2 {
  margin-top: 0;
  color: #  333;
}

p {
  color: #666;
  margin-left: 20px;
  margin-bottom: 10px;

}

h2 {
  margin-top: 20px;
  margin-left: 20px;
}
* {
  box-sizing: border-box;
}
.icon {
  vertical-align: middle;
  font-size: 30px;


}

.maximize p {
  line-height: 2.5;


}
