/* src/components/ScrollToTop.css */
.scroll-to-top {
  position: fixed;
  bottom: 70px;
  right: 15px;
  background-color: #00adb5;
  color: white;
  border: none;
  border-radius: 10%;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 28px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s;
}

.scroll-to-top:hover {
  background-color: #00adb5;
}
