/* Reset para margens e espaçamentos */
body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: hidden;
  background-color: #f4f5f7;
}

/* Container principal da aplicação */
.app-container {
  display: flex;
  gap: 10px;
  width: 100%;
  margin: 0 auto;
  padding: 20px;
}

/* Container para o conteúdo e cards */
.card-container {
  display: grid; /* Alterado para grid */
  grid-template-columns: repeat(3, minmax(250px, 1fr)); /* Configuração para 3 colunas */
  gap: 20px;
  background-color: #f8f8f8;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
}

/* Estilos dos cards */
.card {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  min-width: 250px; /* Limite de largura para os cards */
  width: 100%; /* Card ocupa 100% da coluna */
  height: 200px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.card h3 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
  word-break: break-word; /* Quebra longa de palavras se necessário */
}

.card p {
  font-size: 14px;
  color: #666;
}

/* Botão "CONSULTAR" */
.button {
  font-size: 16px;
  border: 2px solid #00adb5;
  color: #00adb5;
  background-color: transparent;
  padding: 10px;
  margin-top: 10px;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  transition: color 0.3s, background-color 0.3s;
}

.button:hover {
  color: #fff;
  background-color: #00adb5;
}

/* Ajuste de largura para o botão de status */
.button-vendido {
  border: 2px solid #d9534f;
  color: #d9534f;
}

.button-vendido:hover {
  color: #fff;
  background-color: #d9534f;
}

/* Estilos da paginação */
.pagination-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px 0;
  margin-top: 20px;
}
