.login-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;

}

.login-message {
  color: #dc3545; /* Vermelho */
  margin-top: 10px;
  font-size: 1.2em; /* Reduzido o tamanho da fonte para 1.2em */
}

.login-button {
  width: 200px;
  color: #00adb5;
  font-size: 1.3em;
  border: 1px solid #00adb5;
  background-color: #fff;
  transition: color 0.3s, background-color 0.3s;
  text-align: center;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
  display: block;
  margin-top: 20px;
  cursor: pointer;
  white-space: nowrap; /* Evita que o texto seja quebrado em várias linhas */
}

.login-button:hover {
  font-size: 1.3em;
  color: #fff; /* Cor do texto branco */
  background-color: #00adb5; /* Preenchimento com azul ao passar o mouse */
}

.login-icon {
  width: 50px; /* Defina o tamanho desejado */
  height: 50px; /* Defina o tamanho desejado */
}