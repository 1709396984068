/* Reset global */
body, html {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebar-container {
    margin: 0;
    padding: 0;
    min-width: 250px;
    height: 100vh;
    background-color: #f9f9f9;
    border-right: 1px solid #e0e0e0;
    display: flex;
    flex-direction: column;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    overflow-y: auto;
  }
  

/* Search bar inside sidebar */
.sidebar-container .search-bar {
    margin: 0;
    padding: 10px 20px; /* Espaço interno para alinhar bem o input */
    border-bottom: 1px solid #e0e0e0;
    align-items: flex-start;

}

/* Search input in sidebar */
.sidebar-container .search-bar input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
    outline: none;
    transition: border-color 0.3s ease;
    box-sizing: border-box; /* Garante que padding não altere largura total */
    align-items: flex-start;

}

.sidebar-container .search-bar input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
    align-items: flex-start;
}

/* Sector list in sidebar */
.sidebar-container .sector-list {
    flex-grow: 1;
    padding: 10px 20px;
    overflow-y: auto;
  }
  

/* Custom scrollbar */
.sidebar-container .sector-list::-webkit-scrollbar {
    width: 8px;
    align-items: flex-start;
;
}
.sidebar-container .sector-list::-webkit-scrollbar-track {
    background: #f1f1f1;
    align-items: flex-start;

}
.sidebar-container .sector-list::-webkit-scrollbar-thumb {
    background-color: #c0c0c0;
    border-radius: 10px;
    align-items: flex-start;
}
.sidebar-container .sector-list::-webkit-scrollbar-thumb:hover {
    background-color: #a0a0a0;
    align-items: flex-start;

}

/* Sector item styles */
.sidebar-container .sector-item {
    padding: 10px 15px; /* Espaço interno para o item */
    margin: 0; /* Remove margens */
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s ease, color 0.3s ease;
    font-size: 15px;
    color: #333;
    background-color: #ffffff;
    box-sizing: border-box;
    align-items: flex-start;

}

.sidebar-container .sector-item:hover {
    background-color: #007bff;
    color: #ffffff;
    align-items: flex-start;

}

.sidebar-container .sector-item:active {
    background-color: #0056b3;
    align-items: flex-start;

}

/* Mensagem de "Nenhum resultado encontrado" */
.sidebar-container .no-results {
    font-size: 14px;
    color: #777;
    text-align: center;
    margin: 0; /* Remove qualquer margem extra */
    background-color: #f1f1f1;
    border-radius: 4px;
    box-sizing: border-box;
    align-items: flex-start;
}
