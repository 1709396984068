/* Reset de estilos para garantir consistência */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.logo {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  display: flex;
  text-align: left;
  width: 180px;
  background: #00adb5;
}

.search {
  display: none;
  margin-top: 20px;
  margin-right: 25px;
  width: 20px;
  float: right;
}

/* Estilos gerais */
body {
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  color: #333;
}

.App {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

/* Estilos para o link de cookies */
.cookie-link {
  color: #00adb5; /* Cor personalizada para o link */
  text-decoration: none;
  font-weight: bold; /* Texto em negrito */
  display: inline-block; /* Para centralizar o elemento */
  margin-top: 10px; /* Espaçamento superior */
  transition: color 0.3s ease; /* Transição suave para a cor */
}

.cookie-link:hover {
  color: #00796b; /* Cor do link ao passar o mouse */
  text-decoration: underline; /* Sublinhado ao passar o mouse */
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.card-container p {
  font-size: 1.5em;
  font-weight: bold;
}

.container {
  background-color: #f0f0f0; /* Cor de fundo suave */
  color: #333; /* Cor do texto padrão */
}

.container:hover {
  color: #fff; /* Cor do texto branco ao passar o mouse */
}

.card {
  flex: 0 0 calc(25% - 20px);
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding: 10px;
  text-align: center;
}

.card h3 {
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.card p {
  margin-bottom: 5px;
  font-size: 14px;
}

.card a {
  text-decoration: none;
  color: inherit;
}

.card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  transition: all 0.3s ease;
}

.card img {
  width: 100%;
  height: auto;
  border-radius: 8px;
}

.card-busca {
  margin-bottom: 20px;
}

.card-busca input[type="text"] {
  font-size: 16px;
  border: none; /* Remove a borda padrão do select */
  width: 95%;
  margin: 15px;
  padding: 10px; /* Adiciona espaço interno ao input */
}

.card-busca .clear-icon {
  font-size: 20px;
  cursor: pointer;
}

.card-filtro {
  margin-top: 10px;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination button {
  border: 1px solid #00adb5; /* Cor da borda azul */
  background-color: transparent; /* Fundo transparente */
  color: #00adb5; /* Cor do texto azul */
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  border-radius: 5px;
  transition: color 0.3s, background-color 0.3s, border-color 0.3s; /* Transição suave */
}

.pagination button:hover {
  background-color: #00adb5; /* Preenchimento com azul ao passar o mouse */
  color: #fff; /* Cor do texto branco */
}

.pagination button.active {
  background-color: #00adb5;
  color: white;
  border: 1px solid #00adb5;
}

.card-title {
  font-size: 20px;
  text-align: center;
}

.banner {
  margin-bottom: 20px;
  text-align: center;
}

.banner h4 {
  color: #3498db;
  font-size: 25px;
  margin-bottom: 0;
  text-align: center;
}

.banner .icon {
  margin-right: 5px;
}

.button-vendido,
.button-reservado {
  pointer-events: none; /* Desativa o clique */
  opacity: 0.5; /* Diminui a opacidade para indicar que o botão está desativado */
  cursor: not-allowed; /* Muda o cursor para indicar que não é clicável */
}

/* Estilos específicos para dispositivos menores */
@media screen and (max-width: 768px) {
  .card {
    flex: 0 0 calc(50% - 20px);
  }
}

.selectbox select {
  width: 100%; /* Garante que o select ocupe toda a largura do contêiner */
  padding: 10px; /* Adiciona padding interno para maior conforto visual */
  font-size: 16px; /* Mantém o tamanho da fonte consistente */
  border: none; /* Remove a borda padrão do select */
  border-radius: 5px; /* Adiciona bordas arredondadas para o select */
  background-color: #fff; /* Define a cor de fundo do select */
  appearance: none; /* Remove o estilo padrão do navegador para aplicar estilos personalizados */
  cursor: pointer; /* Adiciona um cursor de ponteiro para indicar interatividade */
}

.selectbox:focus {
  outline: none;
  border-color: #008080;
}

.selectbox option {
  padding: 10px;
}

.button {
  width: 50%;
  font-size: 16px;
  border: 2px solid #00adb5;
  background-color: transparent;
  transition: color 0.3s, background-color 0.3s;
  text-align: center;
  margin: 0 auto;
  padding: 0px;
  border-radius: 10px;
  display: block;
  margin-top: 20px;
  cursor: pointer;
}

.button p {
  margin: 0; /* Remover margem padrão */
}

.button:hover {
  color: #fff; /* Cor do texto branco */
}

.button-vendido {
  color: #d9534f;
  border: 2px solid #d9534f;
}

.button-vendido p {
  color: #d9534f !important;
}

.button-vendido:hover {
  color: #fff; /* Cor do texto branco */
  background-color: #d9534f;
}

.button-reservado {
  border: 2px solid #f0ad4e; /* Cor de fundo laranja */
  color: white; /* Cor do texto branco */
}

.button-reservado p {
  color: #f0ad4e !important;
}

.button-consultar {
  background-color: transparent; /* Mantém a cor de fundo atual */
}

.button p {
  margin: 0; /* Remover margem padrão */
  color: #00adb5; /* Cor do texto azul */
}

.button-consultar:hover p {
  color: #fff; /* Cor do texto branco */
  background: #00adb5;
}

@media screen and (max-width: 768px) {
  .button {
    width: 80%;
  }
}

@media screen and (max-width: 576px) {
  .button {
    width: 100%;
  }
}

@media screen and (max-width: 576px) {
  .card {
    flex: 0 0 100%;
  }
}

.setor {
  color: #7d8f89;
}

@media screen and (max-width: 768px) {
  .card-busca input[type="text"] {
    width: 100% !important; /* Garante que o input ocupe toda a largura disponível */
    margin: 15px 0; /* Ajusta a margem para centralizar */
    border: none; /* Remove a borda padrão do select */
  }

  .selectbox {
    width: 100% !important; /* Garante que o dropdown ocupe toda a largura disponível */
    margin: 15px 0; /* Ajusta a margem para centralizar */
  }
}

@media screen and (max-width: 576px) {
  .card-busca input[type="text"] {
    width: 350px !important; /* Garante que o input ocupe toda a largura disponível */
  }

  /* Estilos para o select box */
  .selectbox select {
    width: 100%; /* Garante que o select ocupe toda a largura do contêiner */
    padding: 10px; /* Adiciona padding interno para maior conforto visual */
    font-size: 16px; /* Mantém o tamanho da fonte consistente */
    border: none; /* Remove a borda padrão do select */
    border-radius: 5px; /* Adiciona bordas arredondadas para o select */
    background-color: #fff; /* Define a cor de fundo do select */
    appearance: none; /* Remove o estilo padrão do navegador para aplicar estilos personalizados */
    cursor: pointer; /* Adiciona um cursor de ponteiro para indicar interatividade */
  }

  .selectbox:focus {
    outline: none;
    border-color: #008080;
  }

  .selectbox option {
    padding: 10px;
  }
}

@media (max-width: 480px) {
  .faq-container {
    max-width: 100%; /* Utiliza a largura total em dispositivos muito pequenos */
  }
}

/* FAQ */
.faq-container {
  max-width: 80%; /* Ajustado para ocupar a largura total */
  margin: 20px auto; /* Centralizar na horizontal e espaçamento na parte superior e inferior */
  padding: 10px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Fonte mais próxima do Facebook */
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd; /* Borda mais suave */
}

.faq-item {
  color: #000;
  margin-bottom: 20px; /* Espaçamento maior entre as perguntas */
  border-bottom: 1px solid #ddd; /* Borda entre os itens */
  padding: 0;
}

.faq-question {
  cursor: pointer;
  font-weight: bold;
  background-color: #008080;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  position: relative;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.faq-question h1 {
  color: #fff;
}

.faq-question:hover {
  background-color: #006666; /* Tom mais escuro no hover */
}

.faq-question::after {
  content: '▼'; /* Seta para baixo */
  font-weight: bold;
  color: #fff; /* Cor da seta */
  position: absolute;
  right: 15px; /* Alinhado à direita */
  top: 50%; /* Alinhado verticalmente ao centro */
  transform: translateY(-50%); /* Correção para alinhar verticalmente */
}

/* FAQ */
.faq-container {
  max-width: 80%; /* Ajustado para ocupar a largura total */
  margin: 20px auto; /* Centralizar na horizontal e espaçamento na parte superior e inferior */
  padding: 10px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif; /* Fonte mais próxima do Facebook */
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #ddd; /* Borda mais suave */
}

.faq-item {
  color: #000;
  margin-bottom: 20px; /* Espaçamento maior entre as perguntas */
  border-bottom: 1px solid #ddd; /* Borda entre os itens */
  padding: 0;
}

.faq-question {
  cursor: pointer;
  font-weight: bold;
  background-color: #008080;
  color: #fff;
  padding: 10px 15px;
  margin: 0;
  position: relative;
  transition: background-color 0.3s ease;
  border-radius: 8px;
}

.faq-question h1 {
  color: #fff;
}

.faq-question:hover {
  background-color: #006666; /* Tom mais escuro no hover */
}

.faq-question::after {
  content: '▼'; /* Seta para baixo */
  font-weight: bold;
  color: #fff; /* Cor da seta */
  position: absolute;
  right: 15px; /* Alinhado à direita */
  top: 50%; /* Alinhado verticalmente ao centro */
  transform: translateY(-50%); /* Correção para alinhar verticalmente */
}

.container {
  display: flex; /* Torna os filhos da div em flex-items */
  align-items: center; /* Alinha os filhos verticalmente */
  background-color: #000;
}

.container a,
.container p {
  margin-right: 10px; /* Espaçamento entre os elementos */
}

h1 {
  text-align: left; /* Alinhado à esquerda */
  margin: 10px; /* Espaçamento menor na parte inferior */
  color: #fff;
  font-size: 1em;
}

h3 {
  text-align: left; /* Alinhado à esquerda */
  margin-bottom: 10px; /* Espaçamento menor na parte inferior */
  color: #333;
  font-size: 1.1em;
}

summary {
  cursor: pointer;
  font-weight: bold;
  display: block; /* Alinhamento do texto ao centro */
  position: relative;
  transition: transform 0.3s ease;
  background-color: #008080;
  padding: 5px;
}

summary::after {
  content: '▼'; /* Seta para baixo */
  font-weight: bold;
  color: #fff; /* Cor mais suave para a seta */
  position: absolute;
  right: 20px; /* Alinhado à direita */
  top: 50%; /* Alinhado verticalmente ao centro */
  transform: translateY(-50%); /* Correção para alinhar verticalmente */
}

details[open] summary::after {
  content: '▲'; /* Seta para cima quando aberto */
}

details {
  margin-bottom: 20px; /* Espaçamento maior entre as perguntas */
}

details > p {
  margin-top: 10px;
}

details > p strong {
  color: #007bff;
}

/* Removendo a margem superior do primeiro item */
details:first-of-type {
  margin-top: 0;
}

/* Removendo a borda dos detalhes */
details {
  border: none;
}

/* Estilos para dispositivos móveis */
@media (max-width: 480px) {
  .faq-container {
    max-width: 100%; /* Utiliza a largura total em dispositivos muito pequenos */
  }

  /* Ajuste adicional para remover setas duplicadas */
  .faq-question::after {
    display: none; /* Remove as setas adicionais em dispositivos móveis */
  }

  summary::after {
    right: 10px; /* Ajuste o alinhamento da seta do summary em dispositivos móveis */
  }
}
