/* Estilos padrão */
.cardd {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 20px;
  text-align: left; /* Alinhamento do texto à esquerda */
}

.cardd .title {
  font-size: 2.5em;
  margin-top: 20px;
  color: #333; /* Cor escura para o título */
}

.cardd h1 {
  font-size: 1.8em;
  margin-top: 20px;
  color: #333; /* Cor escura para o título */
}

.cardd h2 {
  font-size: 1.4em;
  margin-top: 20px;
  color: #333; /* Cor escura para o subtítulo */
}

.cardd p {
  font-size: 1em;
  margin-bottom: 10px;
  line-height: 1.5;
  color: #555; /* Cor mais suave para o texto */
}

.cardd strong {
  color: #007bff; /* Cor azul para palavras-chave */
}

.cardd .beneficios {
  margin-top: 20px;
}

.beneficio {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.icon {
  font-size: 1.2em;
  margin-right: 8px;
  color: #007bff; /* Cor azul para ícones */
}

.cardd button {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 0.5em 1em;
  font-size: 1em;
  cursor: pointer;
  margin-top: 20px;
}

.cardd button:hover {
  background-color: #0056b3;
}

/* Estilos para telas menores */
@media screen and (max-width: 768px) {
  .cardd {
    padding: 15px;
    margin: 15px;
  }

  .cardd .title {
    font-size: 2em;
    margin-top: 15px;
  }

  .cardd h1 {
    font-size: 1.5em;
    margin-top: 15px;
  }

  .cardd h2 {
    font-size: 1.2em;
    margin-top: 15px;
  }

  .cardd p {
    font-size: 0.9em;
    margin-bottom: 8px;
  }

  .icon {
    font-size: 1em;
    margin-right: 6px;
  }

  .cardd button {
    font-size: 0.9em;
    margin-top: 15px;
  }
}

/* Formulário */
form {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Etiquetas */
label {
  display: block;
  margin-bottom: 10px;
  font-weight: bold;
  color: #333; /* Cor escura para as etiquetas */
}

/* Inputs e textarea */
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
}

textarea {
  height: 150px;
}

/* Botão */
.button-container {
  text-align: center;
}

button {
  font-weight: bold;
  padding: 14px 40px;
  color: #fff;
  background: #007bff; /* Azul como botão principal */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease;
}

button:hover {
  background: #0056b3; /* Tonalidade mais escura ao passar o mouse */
}

/* Títulos e Parágrafos */
h2, p {
  color: #333;
  margin-bottom: 20px;
}

h2 {
  margin-top: 0;
}

/* Mensagens de erro */
.error {
  color: red;
  font-size: 12px;
  margin-top: -8px;
  margin-bottom: 10px;
}

/* Estilo global */
* {
  box-sizing: border-box;
}

/* Correção de overflow horizontal */
html {
  overflow-x: hidden;
}

/* Dimensionar a imagem */
.form-image {
  display: none; /* Esconder a imagem em telas menores */
}
