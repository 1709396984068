.painel {
  padding: 20px;
  font-family: Arial, sans-serif;
  position: relative; /* Para garantir que o botão se posicione em relação ao .painel */
}

h1 {
  text-align: center;
  color: #00adb5;
}

.btn-logout {
  position: absolute;
  top: 20px; /* Ajuste conforme necessário */
  right: 25px; /* Alinha o botão ao lado direito */
  border: 1px solid #00adb5;
  color: #00adb5;
  padding: 10px 15px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.btn-logout:hover {
  color: #fff;
  background-color: #00adb5;
}

.btn-pesquisar,
.btn-limpar,
.btn-cadastro-dominio,
.btn-editar,
.btn-excluir {
  border: 1px solid #00adb5;
  color: #00adb5;
  padding: 10px 15px;
  margin: 5px;
  cursor: pointer;
  border-radius: 5px;
  background-color: #fff;
  transition: background-color 0.3s ease;

}

.btn-cadastro-dominio,

.btn-editar {
  color: #00adb5;
  border: 1px solid #00adb5;
}

.btn-pesquisar:hover,
.btn-cadastro-dominio:hover,
.btn-limpar:hover,
.btn-editar:hover {
  color: #fff;
  background-color: #00adb5;
}

.btn-excluir {
  color: red;
  border: 1px solid red;
}

.btn-excluir:hover {
  color: #fff;
  background-color: red;
}

input[type="text"] {
  padding: 10px;
  margin: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: calc(100% - 40px);
}

.tabela {
  margin-top: 20px;
}

.tabela table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.tabela th,
.tabela td {
  padding: 10px;
  text-align: left;
  border-bottom: 1px solid #ccc;
}

.tabela th {
  background-color: #00adb5;
  color: white;
  cursor: pointer;
}

.tabela th:hover {
  background-color: #007a85;
}

.feedback {
  color: green;
  margin-bottom: 20px;
}

.grafico {
  margin-top: 20px;
  text-align: center;
}
.painel input[type="text"] {
  margin-right: 10px;
  padding: 8px;
  font-size: 16px;
  width: 80%; /* Ajuste conforme necessário */
}
