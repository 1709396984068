/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: #fff;
  padding: 20px; /* Ajuste o padding para mais conforto */
  border-radius: 12px; /* Bordas arredondadas */
  position: relative;
  width: 90%; /* Ajuste a largura para maior responsividade */
  max-width: 600px;
  max-height: 80vh; /* Limite de altura */
  overflow-y: auto; /* Rolagem vertical se necessário */
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2); /* Sombra mais pronunciada */
  transition: transform 0.3s ease, opacity 0.3s ease; /* Transições suaves */
  transform: scale(0.95);
  opacity: 0;
  animation: modal-show 0.3s forwards;
}

@keyframes modal-show {
  to {
    transform: scale(1);
    opacity: 1;
  }
}

.modal-close {
  position: absolute;
  top: 15px;
  right: 15px;
  border: none;
  background: transparent;
  font-size: 24px; /* Tamanho da fonte ajustado */
  cursor: pointer;
  color: #00adb5;
  transition: color 0.3s ease, background 0.3s ease; /* Transições suaves */
}

.modal-close:hover {
  color: #fff;
  background: #00adb5;
}
