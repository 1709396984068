/* Fonte global */
body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

/* Estilos do rodapé */
.footer {
  background-color: #343a40; /* Cor de fundo mais escura */
  padding: 20px;
  text-align: center;
  border-top: 1px solid #495057; /* Borda superior mais escura */
  font-family: 'Roboto', sans-serif; /* Fonte global */
}

.footer-logo {
  width: 150px; /* Tamanho ajustado */
  margin-bottom: 15px; /* Espaçamento inferior maior */
}

.footer-text {
  color: #f8f9fa; /* Cor do texto clara */
  font-size: 14px;
  line-height: 1.5;
  margin: 0 0 10px; /* Margem inferior ajustada */
}

/* Estilos para o link da política de privacidade */
.footer-link {
  color: #fff; /* Cor personalizada */
  text-decoration: none;
  font-weight: bold; /* Texto em negrito */
  display: inline-block; /* Para centralizar o elemento */
  margin-bottom: 15px; /* Espaçamento inferior */
}

.footer-link:hover {
  text-decoration: underline; /* Sublinhado ao passar o mouse */
}

/* Estilos para texto destacado no rodapé */
.footer .highlighted-text {
  background-color: #495057; /* Cor de fundo mais escura */
  color: #f8f9fa; /* Cor da fonte clara */
  font-weight: bold; /* Texto em negrito */
  padding: 10px 15px; /* Espaçamento interno ajustado */
  border-radius: 5px; /* Borda arredondada */
  display: inline-block; /* Para centralizar o elemento */
  margin-bottom: 15px; /* Espaçamento inferior */
}
