/* WhatsAppIcon.css */
.whatsapp-icon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 50px; /* Ajuste o tamanho conforme necessário */
  height: 50px; /* Ajuste o tamanho conforme necessário */
  border-radius: 50%;
  background-color: #25D366; /* Cor de fundo do ícone */
  color: #fff; /* Cor do ícone */
  text-decoration: none;
  transition: background-color 0.3s ease;
  background-color: #128C7E; /* Cor de fundo ao passar o mouse */

}

.whatsapp-icon:hover {
}
