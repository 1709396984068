/* CadastroDominio.css */

.cadastro-dominio {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 500px;
  margin: 0 auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  background-color: #fff;
  box-sizing: border-box; /* Garante que o padding e bordas sejam incluídos na largura */
  min-height: 400px; /* Altura mínima para evitar que o formulário fique muito pequeno */
}

.cadastro-dominio h2 {
  margin-bottom: 12px;
  font-size: 20px; /* Ajuste para otimizar a leitura em diferentes tamanhos de tela */
  color: #1c1e21;
  font-weight: 600;
}

.cadastro-dominio form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.cadastro-dominio .form-group {
  margin-bottom: 10px; /* Reduz o espaçamento entre os campos */
}

.cadastro-dominio label {
  display: block;
  margin-bottom: 4px; /* Reduz o espaçamento abaixo do label */
  font-size: 14px;
  color: #606770;
}

.cadastro-dominio input {
  width: 100%;
  padding: 8px; /* Ajusta o padding para menor altura */
  border: 1px solid #d6d7da;
  border-radius: 4px;
  font-size: 14px; /* Ajusta o tamanho da fonte */
  margin-top: 2px;
  box-sizing: border-box;
}

.cadastro-dominio button {
  width: 100%;
  padding: 10px; /* Ajusta o padding para menor altura */
  color: #00adb5;
  border: 1px solid #00adb5;
  background-color: #fff;
  border-radius: 4px;
  font-size: 15px; /* Ajusta o tamanho da fonte */
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease;
}

.cadastro-dominio button:hover {
  color: #fff;
  background-color: #00adb5;
}

@media (max-width: 768px) {
  .cadastro-dominio {
    padding: 15px;
    min-height: 350px; /* Ajusta a altura mínima para telas menores */
  }

  .cadastro-dominio h2 {
    font-size: 18px;
  }

  .cadastro-dominio label {
    font-size: 13px;
  }

  .cadastro-dominio input {
    font-size: 13px;
  }

  .cadastro-dominio button {
    font-size: 14px;
    padding: 8px;
  }
}

@media (max-width: 480px) {
  .cadastro-dominio {
    padding: 10px;
    min-height: 300px; /* Ajusta a altura mínima para telas muito pequenas */
  }

  .cadastro-dominio h2 {
    font-size: 16px;
  }

  .cadastro-dominio label {
    font-size: 12px;
  }

  .cadastro-dominio input {
    font-size: 12px;
  }

  .cadastro-dominio button {
    font-size: 13px;
    padding: 6px;
  }
}
