''.layout-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  overflow: hidden;
}

.content {
  display: flex;
  flex: 1;
  width: 100%;
}

main {
  flex: 1;
  padding: 20px;
}
