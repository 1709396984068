/* Estilos do corpo e da tipografia */
body {
  font-family: 'Roboto', Arial, sans-serif;
  color: #444;
  background-color: #f4f4f4;
  margin: 0;
  padding: 0;
  font-size: 16px;
  line-height: 1.9; /* Define a altura da linha */
}

/* Estilos para seções */
.vedo-container {
  border-top: 10px solid transparent;
  transition: border-top-color 0.3s ease;
  border-top-color: #d3d3d3;
  padding: 6%; /* Adiciona espaçamento interno para separar o conteúdo */
  margin-bottom: 20px; /* Adiciona margem inferior para separar as seções */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.vedo-container p {
  font-size: 18px;
  margin-bottom: 10px;
}

.vedo-container h2 {
  font-size: 24px;
  margin-bottom: 20px;
}

.vedo-container ul {
  list-style: none;
  padding: 0;
}

.vedo-container ul li {
  margin-bottom: 10px;
}

.vedo-container h1 {
  position: relative;
  font-size: 1.4rem;
}

.vedo-container h3 {
  position: relative;
  font-size: 1.2rem;
}

.vedo-container h3::before {
  content: '';
  position: absolute;
  bottom: -6px; /* Ajuste a posição da linha */
  left: 0;
  width: 20%;
  height: 6px; /* Altura da linha */
  background: linear-gradient(to bottom, #ddd, #3498db); /* Gradiente de cor */
}

/* Estilos para o banner do parceiro */
.banner-parceiro {
  text-align: center;
}

.banner-parceiro img {
  width: 70%;
  height: auto;
  border-radius: 10px; /* Suaviza as bordas da imagem */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Adiciona sombra */
}

/* Estilos para o conteúdo principal */
.vedo-content {
  padding: 130px;
  margin: 0 auto; /* Centraliza o conteúdo */
  max-width: 1200px; /* Define uma largura máxima para o conteúdo */
}

/* Estilos para a tabela */
.vedo-content table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto; /* Centraliza a tabela horizontalmente */
  border-radius: 10px; /* Suaviza as bordas da tabela */
  overflow: hidden; /* Esconde o conteúdo que ultrapassa a tabela */
}

.vedo-content table th,
.vedo-content table td {
  border: 1px solid #ddd;
  padding: 12px; /* Aumenta o padding para melhorar a aparência */
  text-align: left; /* Alinha o texto à esquerda */
}

.vedo-content table th {
  background-color: #21a8c4;
  color: #fff; /* Altera a cor do texto para melhor contraste */
}

.vedo-content table tr:nth-child(even) {
  background-color: #f9f9f9; /* Altera a cor de fundo das linhas pares */
}

.vedo-content table tr:hover {
  background-color: #f2f2f2; /* Altera a cor de fundo ao passar o mouse */
}

/* Estilos para o botão "rolar para o topo" */
#scrollToTopBtn {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  font-size: 24px;
  background-color: #21a8c4;
  color: white;
  border: none;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
}

#scrollToTopBtn:hover {
  background-color: #1b879e;
}

/* Estilos para o ícone do botão */
#scrollToTopBtn > svg {
  margin-top: 3px; /* Ajusta a posição vertical do ícone */
}

/* Estilos específicos para o componente DuvidasFrequentes */
.trilha-publico {
  display: flex;
  justify-content: space-between;
  gap: 20px;
}

.card {
  width: 300px; /* Largura fixa do card */
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
  margin-bottom: 10px;
  background-color: #f3f3f3;
  overflow: hidden; /* Oculta qualquer conteúdo que ultrapassa as bordas do card */
}

.card img {
  width: 100%;
  height: auto;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.card h2 {
  font-size: 1.2rem;
  padding: 10px;
  margin: 0;
}

.perguntas {
  padding: 10px;
}

.perguntas a {
  display: block;
  margin-bottom: 10px;
}

