/* Estilo do header */
header {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  background: #00adb5; /* Cor de fundo do cabeçalho */
  z-index: 9999; /* Ajuste o valor do z-index conforme necessário */
  padding: 0px 0;
}

/* Estilo da navbar */
.navbar {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background: #008080; /* Cor de fundo da navbar */
  padding: 10px 20px;
}

.navbar a {
  text-decoration: none;
  color: #fff; /* Cor do texto dos links */
  margin-right: 20px;
  padding: 10px 15px;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.navbar a:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
  border-bottom: none; /* Remove a borda inferior no hover */
}

/* Estilo especial para o link Quero Vender */
.highlighted-link {
  color: #fff;
  background-color: #ff5722; /* Cor de destaque */
  padding: 10px 20px;
  border-radius: 25px; /* Ajuste para uma aparência mais suave */
  margin-right: 20px; /* Mantém o espaçamento entre links */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Adiciona uma sombra suave */
  font-weight: bold; /* Torna o texto em negrito */
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.highlighted-link:hover {
  background-color: #e64a19; /* Cor de destaque ao passar o mouse */
  color: #fff;
  transform: scale(1.05); /* Leve aumento ao passar o mouse */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2); /* Aumenta a sombra no hover */
}

/* Estilos responsivos */
@media (max-width: 768px) {
  .navbar {
    flex-direction: column; /* Alinha os itens verticalmente em dispositivos móveis */
    align-items: flex-start; /* Alinha itens no início à esquerda */
    padding: 10px;
  }

  .navbar a {
    margin: 5px 0; /* Espaçamento menor entre os links */
    padding: 10px;
    width: 100%; /* Links ocupam a largura total do container */
    text-align: center; /* Centraliza o texto dos links */
  }

  .highlighted-link {
    margin: 10px 0; /* Espaçamento entre os links em dispositivos móveis */
    width: 100%; /* Botão ocupa a largura total do container */
    text-align: center; /* Centraliza o texto do botão */
  }
}

/* Estilos dos ícones sociais */
.social-icons {
  display: flex;
  gap: 10px;
  align-items: center; /* Alinha verticalmente os ícones */
  margin-left: auto; /* Garante que os ícones fiquem alinhados à direita da navbar */
}

.social-link {
  text-decoration: none; /* Remove sublinhado dos ícones */
}

.social-icon {
  font-size: 26px; /* Tamanho dos ícones */
  color: #fff; /* Cor dos ícones */
  transition: color 0.3s ease; /* Transição suave para a mudança de cor */
}

.social-icon:hover {
  color: #0073b1; /* Cor do ícone ao passar o mouse (Facebook) */
}

.social-icon:nth-of-type(2):hover {
  color: #25D366; /* Cor do ícone ao passar o mouse (WhatsApp) */
}
.reset-button{
padding: 5, 10  
}