/* Contato.css */

/* Container do formulário */
.contato-container {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  margin-top: 20px;
  padding: 20px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}


/* Título e ícone do WhatsApp */
.contato-header {
  display: flex;
  align-items: center; /* Alinha verticalmente o ícone e o texto */
  justify-content: center; /* Centraliza o conteúdo horizontalmente */
  margin-bottom: 20px; /* Espaçamento abaixo do título */
}

.contato-header p {
  color: #333;
  font-size: 24px;
  margin: 0; /* Remove a margem padrão do <p> */
}

.contato-header h1 {
    width: 90%;
    color: #333333;
    background-color: #f5f5f5 ;

}

.whatsapp-icon {
  margin-left: 10px; /* Espaçamento entre o texto e o ícone */
}

/* Etiquetas */
label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #333; /* Cor escura para as etiquetas */
}

/* Inputs e textarea */
input[type="text"],
input[type="email"],
textarea {
  width: 100%;
  padding: 14px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  font-size: 16px;
  font-family: Arial, sans-serif; /* Defina a fonte aqui */
}

/* Estilo para o textarea */
textarea {
  height: 150px;
  resize: vertical; /* Permite redimensionar verticalmente */
}

/* Foco nos inputs e textarea */
input[type="text"]:focus,
input[type="email"]:focus,
textarea:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* Estilo específico para o placeholder */
input::placeholder,
textarea::placeholder {
  color: #999; /* Cor do texto do placeholder igual ao texto dos inputs */
}

/* Botão */
.button-container {
  text-align: center;
}

.button-container button {
  font-weight: bold;
  color: #00adb5;
  padding: 14px 40px;
  background: #fff; /* Azul como botão principal */
  border: 1px solid #00adb5; /* Cor da borda azul */
  border-radius: 5px;
  cursor: pointer;
  transition: background 0.3s ease, color 0.3s ease;
}

.button-container button:hover {
  background: #00adb5;
  color: #fff; /* Cor do texto branco */
}

/* Mensagens de erro e sucesso */
.error, .success {
  font-size: 16px; /* Ajustado para se alinhar com o tamanho da fonte dos inputs */
  color: #333; /* Cor igual ao texto dos inputs */
  margin-top: 10px;
}

.error {
  color: red;
}

.success {
  color: green;
}

/* Estilo global */
* {
  box-sizing: border-box;
}

/* Correção de overflow horizontal */
html {
  overflow-x: hidden;
}

/* Imagem do formulário */
.form-image {
  max-width: 100%; /* Mantém a imagem dentro dos limites do contêiner */
  height: auto; /* Mantém a proporção da imagem */
  max-height: 200px; /* Ajuste a altura máxima conforme necessário */
  margin-bottom: 20px;
}

/* Responsividade */
@media (min-width: 768px) {
  .form-image {
    display: block;
  }
}
